<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      :confirm_function="loading.function"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <div class="d-flex align-center px-6 pt-2">
      <v-spacer></v-spacer>
      <v-btn
        v-if="isEditMode && local_user_profile.is_active"
        color="#f5aa00"
        class="font-weight-bolder btn-default py-4 px-4 mx-2 mt-sm-auto mt-4"
        @click="onClick(edit_mode_enum.Inactive)"
      >
        停用帳號
      </v-btn>
      <v-btn
        v-if="isEditMode && !local_user_profile.is_active"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-primary
          py-4
          px-4
          mx-2
          mt-sm-auto mt-4
        "
        @click="onClick(edit_mode_enum.Active)"
      >
        啟用帳號
      </v-btn>
      <v-btn
        v-if="!readOnly"
        color="#cb0c9f"
        :class="getClass()"
        @click="onClick(edit_mode)"
      >
        {{ formTitle }}
      </v-btn>
    </div>

    <div class="px-6 pb-4 pt-2">
      <v-form>
        <v-row>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Last Name")
            }}</label>
            <v-text-field
              v-model="local_user_profile.last_name"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("First Name")
            }}</label>
            <v-text-field
              v-model="local_user_profile.first_name"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">帳號</label>
            <v-text-field
              hide-details
              outlined
              v-model="local_user_profile.username"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="!isAddMode || readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">生日</label>
            <v-menu
              v-model="local_user_profile.birth_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!isAddMode || readOnly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  class="font-size-input border border-radius-md mt-2"
                  :value="displayChineseYear(local_user_profile.birthday)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isAddMode || readOnly"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="local_user_profile.birthday"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="local_user_profile.birth_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">身分證字號</label>
            <v-text-field
              hide-details
              outlined
              v-model="local_user_profile.id_number"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="!isAddMode || readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">角色</label>
            <v-select
              v-if="isAddMode || isEditMode"
              :items="getRoles()"
              v-model="local_user_profile.role"
              class="
                input-style
                font-size-input
                border-radius-md
                select-style
                mt-2
              "
              outlined
              single-line
              hide-details
              item-text="text"
              :disabled="readOnly"
            ></v-select>
            <v-text-field
              v-else
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :value="local_user_profile.role | getText(role_enum_str)"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">Email</label>
            <v-text-field
              hide-details
              outlined
              v-model="local_user_profile.email"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="!isAddMode || readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Mobile")
            }}</label>
            <v-text-field
              v-model="local_user_profile.mobile_phone"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">營業員證號</label>
            <v-text-field
              v-model="local_user_profile.sales_id"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="!isAddMode && readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">到期日</label>
            <v-menu
              v-model="local_user_profile.sales_expire_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!isAddMode && me"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  class="font-size-input border border-radius-md mt-2"
                  :value="
                    displayChineseYear(local_user_profile.sales_id_expire)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isAddMode && me"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="local_user_profile.sales_id_expire"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="local_user_profile.sales_expire_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12">
            <label class="text-md font-weight-bolder ms-1">戶籍地址</label>
            <v-text-field
              hide-details
              outlined
              v-model="local_user_profile.registered_address"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <label class="text-md font-weight-bolder ms-1">聯絡地址</label>
            <v-text-field
              hide-details
              outlined
              v-model="local_user_profile.contact_address"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Company")
            }}</label>
            <v-text-field
              v-model="local_user_profile.company"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Department")
            }}</label>
            <v-text-field
              v-model="local_user_profile.department"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="3" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Job Title")
            }}</label>
            <v-text-field
              v-model="local_user_profile.job_title"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="3" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Office")
            }}</label>
            <v-text-field
              v-model="local_user_profile.office_phone"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="3" cols="6">
            <label class="text-md font-weight-bolder ms-1">{{
              $t("Ext Number")
            }}</label>
            <v-text-field
              v-model="local_user_profile.ext_phone"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="3" cols="6">
            <label class="text-md font-weight-bolder ms-1">Line ID</label>
            <v-text-field
              v-model="local_user_profile.line_id"
              hide-details
              outlined
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
import { role_enum_str, edit_mode_enum } from "@/definitions.js";
export default {
  name: "user-basic-info",
  props: {
    edit_mode: {
      type: Number,
      default: edit_mode_enum.View,
    },
    user_profile: {
      type: Object,
      default: () => {
        return {
          id: null,
          email: null,
          username: null,
          first_name: null,
          last_name: null,
          mobile_phone: null,
          company: null,
          department: null,
          office_phone: null,
          ext_phone: null,
          job_title: null,
          id_number: null,
          birthday: null,
          contact_address: null,
          registered_address: null,
          sales_id: null,
          line_id: null,
          sales_id_expire: null,
          sales_id_expire_menu: false,
        };
      },
    },
    me: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CommonConfirm,
    CommonLoading,
  },
  mixins: [HttpCommonMixin],
  data() {
    return {
      edit_mode_enum: edit_mode_enum,
      role_enum_str: role_enum_str,
      local_user_profile: {
        birth_menu: false,
        sales_expire_menu: false,
      },
      default_user_profile: {
        birth_menu: false,
        sales_expire_menu: false,
      },
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
    };
  },
  watch: {
    user_profile: {
      handler: function () {
        this.setUserProfile(this.user_profile);
      },
    },
    deep: true,
  },
  computed: {
    formTitle() {
      return this.isAddMode ? "新增帳號" : "更新資料";
    },
    isAddMode() {
      return this.edit_mode == edit_mode_enum.Create;
    },
    isEditMode() {
      return this.edit_mode == edit_mode_enum.Edit;
    },
    readOnly() {
      return this.edit_mode == edit_mode_enum.View;
    },
  },
  mounted() {
    this.setUserProfile(this.user_profile);
  },
  methods: {
    ...mapGetters(["getToken", "getRoles", "isSuperUser"]),
    getClass() {
      if (this.isAddMode) {
        // return "bg-gradient-primary"
        return "font-weight-bolder  btn-default bg-gradient-primary py-4 px-4 mt-sm-auto mt-4";
      }
      return "font-weight-bolder  btn-default bg-gradient-default py-4 px-4 mt-sm-auto mt-4";
    },
    setUserProfile(user_profile) {
      // merge all user_profile into local_user_profile
      this.local_user_profile = Object.assign(
        {},
        this.local_user_profile,
        user_profile
      );
    },
    reset() {
      // reset the local_user_profile
      this.local_user_profile = JSON.parse(
        JSON.stringify(this.default_user_profile)
      );
    },
    getInfo() {
      let data = {};
      const obj = this.local_user_profile;
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var val = obj[key];
          // use val
          data[key] = val;
        }
      }

      return data;
    },
    onClick(val) {
      switch (val) {
        case edit_mode_enum.Create:
          this.confirm.title = "新增使用者";
          this.confirm.message = `確定要新增${this.local_user_profile.username}嗎?`;
          this.confirm.function = this.addUserProfile;
          this.loading.title = "新增使用者";
          this.loading.message = `新增${this.local_user_profile.username}中...`;
          break;
        case edit_mode_enum.Edit:
          this.confirm.title = "更新使用者";
          this.confirm.message = `確定要更新${this.local_user_profile.username}的資料嗎?`;
          this.confirm.function = this.updateProfile;
          break;
        case edit_mode_enum.Inactive:
          this.confirm.title = "停用使用者";
          this.confirm.message = `確定要停用${this.local_user_profile.username}嗎?`;
          this.confirm.function = this.disableUser;
          break;
        case edit_mode_enum.Active:
          this.confirm.title = "啟用使用者";
          this.confirm.message = `確定要啟用${this.local_user_profile.username}嗎?`;
          this.confirm.function = this.enableUser;
          break;
      }
      this.confirm.dialog = true;
    },
    disableUser() {
      // local_user_profile
      this.confirm.dialog = false;
      console.log("disable user", this.local_user_profile);
      this.active_or_disable_user(false);
    },
    enableUser() {
      this.confirm.dialog = false;
      console.log("enable user", this.local_user_profile);
      this.active_or_disable_user(true);
    },
    active_or_disable_user(enable) {
      // /api/v1/users/active
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/`;
      const data = {
        id: this.local_user_profile.id,
        is_active: enable,
      };
      this.doHttpDeleteDataData(
        url,
        data,
        this.onActiveOrDisableSuccess,
        this.onActiveOrDisableFail
      );
    },
    onActiveOrDisableSuccess() {
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.message = `使用者${this.local_user_profile.username} 更新成功`;
      this.local_user_profile.is_active = !this.local_user_profile.is_active;
    },
    onActiveOrDisableFail() {
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.message = `使用者${this.local_user_profile.username} 更新失敗`;
      this.local_user_profile.is_active = !this.local_user_profile.is_active;
    },
    closeConfirmDialog() {
      this.confirm = {
        dialog: false,
        title: null,
        message: null,
        function: null,
      };
    },
    checkInfo(data) {
      // check info
      const check_list = [
        { name: "email", msg: "請輸入Email" },
        { name: "first_name", msg: "請輸入姓" },
        { name: "last_name", msg: "請輸入名" },
        { name: "birthday", msg: "請輸入生日" },
        { name: "username", msg: "請輸入帳號" },
      ];
      for (let i = 0; i < check_list.length; i++) {
        if (
          data[check_list[i].name] == null ||
          data[check_list[i].name] == ""
        ) {
          return check_list[i].msg;
        }
      }
      return null;
    },
    addUserProfile() {
      let data = this.getInfo();
      let msg = this.checkInfo(data);
      if (msg != null) {
        this.confirm.dialog = false;
        this.loading = {
          title: "訊息",
          message: msg,
          show_loading: false,
          show_confirm: true,
          dialog: true,
        };
        return;
      }

      console.log("Add user profile");
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/`;

      this.loading = {
        title: "新增使用者",
        message: "新增使用者中...",
        show_loading: true,
        dialog: true,
      };

      setTimeout(() => {
        this.doHttpPost(
          url,
          data,
          this.onAddUserDone,
          this.onAddUserFail,
          this.closeConfirmDialog
        );
      }, 100);
    },
    onAddUserDone() {
      // alert("新增使用者成功");
      console.log("add user profile done");
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.message = `使用者${this.local_user_profile.username} 新增使用者成功`;
      this.loading.function = this.addConfirm;
      this.loading.dialog = true;
    },
    onAddUserFail(data) {
      console.log("pares fail", data);

      if (data.response && data.response.data && data.response.data.detail) {
        console.log("pares response", data.response.data);
        console.log(typeof detail);
        const msg = CommonUtility.parseError(data.response.data.detail);
        if (msg.length > 0) {
          setTimeout(() => {
            this.loading = {
              title: "新增使用者",
              message: msg,
              show_loading: false,
              show_confirm: true,
              dialog: true,
            };
          }, 200);
          return;
        }
      }

      setTimeout(() => {
        this.loading = {
          title: "新增使用者",
          message: "新增使用者失敗",
          show_loading: false,
          show_confirm: true,
          dialog: true,
        };
      }, 200);
    },
    updateProfile() {
      let url = this.me
        ? `${process.env.VUE_APP_SERVER_URL}/api/v1/users/me`
        : `${process.env.VUE_APP_SERVER_URL}/api/v1/users/${this.local_user_profile.id}`;
      console.log("update the user profile...", url);
      let data = this.getInfo();
      this.confirm.dialog = false;
      this.loading = {
        title: "更新使用者",
        show_loading: true,
        dialog: true,
      };

      setTimeout(() => {
        // currentObj.verifying = verifying_enum.Done;
        this.doHttpPut(url, data, this.onUpdateSuccess, this.onUpdateFail);
      }, 100);
    },
    onUpdateSuccess() {
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.message = `使用者${this.local_user_profile.username} 更新成功`;
    },
    onUpdateFail(data) {
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.message = `使用者${this.local_user_profile.username} 更新失敗`;
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    addConfirm() {
      this.loading = {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      };
      this.$router.push({ name: "UserList" });
    },
  },
};
</script>
